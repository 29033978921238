<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Regras de permissões</h3>
        </div>
        <div class="card-body table-responsive">
          <b-table
              :fields="['id','name', 'acoes']"
              :items="lista_roles"
              :per-page="perPage"
              :current-page="currentPage"
              id="roles-table"
              class="table table-head-custom  table-vertical-center table-head-bg table-borderless"
              show-empty
              empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{item}">
              <div class="text-right w-100">
                <button
                    class="btn btn-icon btn-light btn-sm mx-1 text-primary d-none"
                    v-b-tooltip.hover title="Vincular Usuários"
                    @click.prevent="abrirModalVinculo(item)"
                >
                  <i class="far fa-user text-primary"></i>
                </button>
                <button
                    class="btn btn-icon btn-light btn-sm mx-1 text-info"
                    v-b-tooltip.hover title="Configurações da regra"
                    @click.prevent="editarRegra(item)"
                >
                  <i class="fas fa-cogs text-info"></i>
                </button>
              </div>
            </template>
          </b-table>

          <b-pagination
              v-model="currentPage"
              :total-rows="lista_roles.length"
              :per-page="perPage"
              aria-controls="roles-table"
          >
          </b-pagination>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";

export default {
  data() {
    return {
      vincular_usuario_modal: false,
      selectedItem: {},
      currentPage: 1,
      perPage: 10,
      vinculo: {
        role_id: "",
        permission_id: "",
      },
      usuario_id: "",
      vinculacao: 0,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Permissoes"}]);
  },
  created() {
    this.get_permisoes_roles();
  },
  computed: {
    lista_roles() {
      return this.$store.state.permissoes.lista_roles;
    },
    lista_roles_permissoes() {
      return this.$store.state.permissoes.lista_roles_permissoes;
    },
  },
  methods: {
    abrirModalVinculo(item) {
      this.vincular_usuario_modal = true
      this.selectedItem = item
    },
    editarRegra(value) {
      this.$store.dispatch("configEmpresa/atualizar", value);
      this.$router.push({name: "createRule"});
    },
    async get_permisoes_roles() {
      
      await this.$store.dispatch("permissoes/all_roles");
      
      this.$store.dispatch("configEmpresa/atualizar", "");
    },
    async listar_permissoes_role(value) {
      this.vinculacao = 2;
      this.vinculo.role_id = value.id;
      
      await this.$store.dispatch("permissoes/all_permissions_regra", value.id);
      await this.$store.dispatch("permissoes/all_permissions");
      
    },
    async listar_usuarios_role(value) {
      this.vinculacao = 1;
      this.vinculo.role_id = value.id;
      // await this.$store.dispatch("listar_funcionario");
      await this.$store.dispatch("permissoes/all_roles");
      

    },
  },
};
</script>

<style>
</style>